import TranslationKeys from "../../../contexts/I18nContext/translationKeys";
import {
  WhmBox,
  WhmIcons,
  WhmInputAdornment,
  WhmTextField,
  whmTheme,
} from "@securitize/reactjs-whm";
import { Translate, TranslateHtml } from "../../Translation/Translate";
import { InfoCard } from "../shared/InfoCard";
import React from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { InvestorDto } from "../../../data/dtos/investor.dto";
import {
  columnBox,
  placeholderTextFieldStyle,
  textFieldStyle,
  wrapBox,
} from "../shared/styles";

interface InfoCardProps {
  investorInfoQuery: UseQueryResult<InvestorDto, Error>;
}

export const PersonalDetailsCard: React.FC<
  React.PropsWithChildren<InfoCardProps>
> = ({ investorInfoQuery }) => {
  return (
    <InfoCard
      title={
        <Translate
          translationKey={
            TranslationKeys.PROFILE_PERSONAL_INFORMATION_PERSONAL_DETAILS_CARD_TITLE
          }
        />
      }
      subtitle={
        <Translate
          translationKey={
            TranslationKeys.PROFILE_PERSONAL_INFORMATION_PERSONAL_DETAILS_CARD_SUBTITLE
          }
        />
      }
      footer={
        <TranslateHtml
          translationKey={
            TranslationKeys.PROFILE_PERSONAL_INFORMATION_PERSONAL_DETAILS_CARD_FOOTER
          }
        />
      }
    >
      <WhmBox
        component="form"
        sx={{
          ...columnBox,
          marginTop: "32px",
          marginBottom: "24px",
        }}
        noValidate
        autoComplete="off"
      >
        <WhmBox
          sx={{
            ...wrapBox,
            gap: "16px",
          }}
        >
          <WhmTextField
            id="firstName"
            inputProps={{
              "data-test-id":
                "profile-personal-information-personal-details-first-name-field",
            }}
            label={
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_PERSONAL_INFORMATION_FIELDS_FIRST_NAME
                }
              />
            }
            defaultValue={investorInfoQuery.data?.details.firstName}
            sx={textFieldStyle}
            disabled
          />
          <WhmTextField
            id="middleName"
            inputProps={{
              "data-test-id":
                "profile-personal-information-personal-details-middle-name-field",
            }}
            label={
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_PERSONAL_INFORMATION_FIELDS_MIDDLE_NAME
                }
              />
            }
            defaultValue={investorInfoQuery.data?.details.middleName}
            sx={textFieldStyle}
            disabled
          />
          <WhmTextField
            id="lastName"
            inputProps={{
              "data-test-id":
                "profile-personal-information-personal-details-last-name-field",
            }}
            label={
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_PERSONAL_INFORMATION_FIELDS_LAST_NAME
                }
              />
            }
            defaultValue={investorInfoQuery.data?.details.lastName}
            sx={textFieldStyle}
            disabled
          />
        </WhmBox>

        <div
          style={{
            display: "flex",
            gap: "16px",
            width: "100%",
            marginTop: "16px",
          }}
        >
          <WhmTextField
            id="birthdate"
            label={
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_PERSONAL_INFORMATION_FIELDS_BIRTHDATE
                }
              />
            }
            type="date"
            disabled
            defaultValue={
              investorInfoQuery.data?.details.birthday !== undefined
                ? new Date(investorInfoQuery.data.details.birthday)
                    .toISOString()
                    .slice(0, 10)
                : ""
            }
            sx={textFieldStyle}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              "data-test-id":
                "profile-personal-information-personal-details-birthdate-field",
            }}
            InputProps={{
              endAdornment: (
                <WhmInputAdornment position="end">
                  <WhmIcons.CalendarTodayOutlined
                    sx={{
                      width: "24px",
                      height: "24px",
                      color: whmTheme.palette.action.disabled,
                    }}
                  />
                </WhmInputAdornment>
              ),
            }}
          />

          <WhmBox sx={placeholderTextFieldStyle} />
          <WhmBox sx={placeholderTextFieldStyle} />
        </div>
      </WhmBox>
    </InfoCard>
  );
};
