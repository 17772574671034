import React, { useContext, useEffect } from "react";
import {
  WhmGrid,
  WhmSkeleton,
  WhmStack,
  WhmTab,
  WhmTabs,
  WhmTypography,
} from "@securitize/reactjs-whm";
import { Information } from "./information/Information";
import { ReactComponent as PersonalInformationIcon } from "@securitize/assets-sec-ui/dist/icons/svg/entity-info.svg";
import { ReactComponent as TaxIcon } from "@securitize/assets-sec-ui/dist/icons/svg/tax.svg";
import { ReactComponent as InvestmentProfileIcon } from "@securitize/assets-sec-ui/dist/icons/svg/acreditation.svg";
import { ReactComponent as SettingsIcon } from "@securitize/assets-sec-ui/dist/icons/svg/settings.svg";
import { ReactComponent as MembersIcon } from "@securitize/assets-sec-ui/dist/icons/svg/members.svg";
import { Translate } from "../Translation/Translate";
import TranslationKeys from "../../contexts/I18nContext/translationKeys";
import useUserInfo from "../../hooks/useUserInfo";
import { useNavigate, useParams } from "react-router";
import {
  PORTFOLIO_PATH,
  profilePaths,
  tabRoutes,
  tabRoutesToProfilePaths,
} from "./routing-constants";
import { InvestmentProfile } from "./investment-profile/InvestmentProfile";
import { useGetPendingActionsQuery } from "../../data/useProfile";
import TabIcon from "./shared/TabIcon";
import Settings from "./settings/Settings";
import { InvestorTypes } from "../../data/types/investors";
import Snackbar, { SnackbarProvider } from "../shared/Snackbar";
import { ProfileContext } from "../../contexts/ProfileContext";
import KeycloakSettings from "./settings/KeycloakSettings";
import { AuthorizedMembersCard } from "./information/AuthorizatedMembersCard";
import TaxCenterMFE from "../../views/TaxCenter";

const ALLOWED_TAB_PATHS = Object.values(tabRoutes);

const tabStyle = {
  textTransform: "none",
  minHeight: "48px",
  gap: "6px",
};

export const Profile = () => {
  const userInfo = useUserInfo();
  const { isKeycloakEnabled, basePath } = useContext(ProfileContext);

  const navigate = useNavigate();

  const pendingActionsQuery = useGetPendingActionsQuery();

  const isEntity = userInfo.investorType === InvestorTypes.ENTITY;

  // Tab navigation handling
  const { tab } = useParams();

  // Capture paths that are not allowed or available
  useEffect(() => {
    if (!tab || !ALLOWED_TAB_PATHS.includes(tab)) {
      navigate(profilePaths(basePath).information, { replace: true });
    }

    if (!isEntity && tab === tabRoutes.authorizedMembers) {
      navigate(PORTFOLIO_PATH, { replace: true });
      return;
    }
  }, [tab, navigate, isEntity, basePath]);

  // Handle tab clicks
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    if (ALLOWED_TAB_PATHS.includes(newValue)) {
      navigate(tabRoutesToProfilePaths(basePath)[newValue]);
    }
  };

  const userName =
    (userInfo.investorType === "individual"
      ? userInfo.fullName
      : userInfo.entityName) || userInfo.investorUserEmail;

  return (
    <SnackbarProvider>
      <WhmStack sx={{ padding: "0" }}>
        <WhmTypography variant="h1">
          <Translate translationKey={TranslationKeys.PROFILE_TITLE} />
        </WhmTypography>

        <WhmGrid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ marginTop: "16px" }}
        >
          <WhmTypography variant="h5" data-test-id="profile-user-name">
            {userName}
          </WhmTypography>
        </WhmGrid>

        {pendingActionsQuery.isLoading ? (
          <div
            id="tabs-skeleton"
            data-test-id="profile-tabs-skeleton"
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "4px",
              marginTop: "16px",
            }}
          >
            <WhmSkeleton variant="rounded" width={210} height={50} />
            <WhmSkeleton variant="rounded" width={141} height={50} />
            <WhmSkeleton variant="rounded" width={191} height={50} />
            <WhmSkeleton variant="rounded" width={125} height={50} />
          </div>
        ) : (
          <WhmTabs
            value={tab || tabRoutes.information}
            onChange={handleTabChange}
            variant="scrollable"
            allowScrollButtonsMobile
            aria-label="information-tabs"
            sx={{
              marginTop: "16px",
              display: "flex",
            }}
          >
            <WhmTab
              icon={
                <TabIcon
                  icon={
                    <PersonalInformationIcon
                      width={24}
                      height={24}
                      fill="currentColor"
                    />
                  }
                  hasBadge={
                    pendingActionsQuery.data?.profile.personalInformation
                      .pendingAction
                  }
                  badgeTestId="profile-personal-information-badge"
                />
              }
              data-test-id="profile-personal-information-tab"
              iconPosition="start"
              value={tabRoutes.information}
              label={
                <Translate
                  translationKey={
                    isEntity
                      ? TranslationKeys.PROFILE_ENTITY_INFORMATION_TAB_TITLE
                      : TranslationKeys.PROFILE_PERSONAL_INFORMATION_TAB_TITLE
                  }
                />
              }
              sx={tabStyle}
            />
            {isEntity && (
              <WhmTab
                icon={
                  <MembersIcon width={24} height={24} fill="currentColor" />
                }
                iconPosition="start"
                data-test-id="authorized-members-tab"
                value={tabRoutes.authorizedMembers}
                label={
                  <Translate
                    translationKey={
                      TranslationKeys.PROFILE_AUTHORIZED_MEMBERS_TAB_TITLE
                    }
                  />
                }
                sx={tabStyle}
              />
            )}
            <WhmTab
              icon={
                <TabIcon
                  icon={<TaxIcon width={24} height={24} fill="currentColor" />}
                  hasBadge={
                    pendingActionsQuery.data?.profile.taxCenter.pendingAction
                  }
                  badgeTestId="profile-tax-center-badge"
                />
              }
              iconPosition="start"
              data-test-id="profile-tax-center-tab"
              value={tabRoutes.taxCenter}
              label={
                <Translate
                  translationKey={TranslationKeys.PROFILE_TAX_CENTER_TAB_TITLE}
                />
              }
              sx={tabStyle}
            />
            <WhmTab
              icon={
                <TabIcon
                  icon={
                    <InvestmentProfileIcon
                      width={24}
                      height={24}
                      fill="currentColor"
                    />
                  }
                  hasBadge={
                    pendingActionsQuery.data?.profile.investmentProfile
                      .pendingAction
                  }
                  badgeTestId="profile-investment-profile-badge"
                />
              }
              iconPosition="start"
              data-test-id="profile-investment-profile-tab"
              value={tabRoutes.investmentProfile}
              label={
                <Translate
                  translationKey={
                    TranslationKeys.PROFILE_INVESTMENT_PROFILE_TAB_TITLE
                  }
                />
              }
              sx={tabStyle}
            />
            <WhmTab
              icon={<SettingsIcon width={24} height={24} fill="currentColor" />}
              iconPosition="start"
              data-test-id="profile-settings-tab"
              value={tabRoutes.settings}
              label={
                <Translate
                  translationKey={TranslationKeys.PROFILE_SETTINGS_TAB_TITLE}
                />
              }
              sx={tabStyle}
            />
          </WhmTabs>
        )}

        {tab === tabRoutes.information && (
          <Information
            isPendingActionsLoading={pendingActionsQuery.isLoading}
          />
        )}

        {tab === tabRoutes.authorizedMembers && isEntity && (
          <AuthorizedMembersCard
            isPendingActionsLoading={pendingActionsQuery.isLoading}
          />
        )}

        {tab?.includes(tabRoutes.taxCenter) && (
          <TaxCenterMFE
            isPendingActionsLoading={pendingActionsQuery.isLoading}
          />
        )}

        {tab === tabRoutes.investmentProfile && (
          <InvestmentProfile
            isPendingActionsLoading={pendingActionsQuery.isLoading}
          />
        )}

        {tab === tabRoutes.settings &&
          (isKeycloakEnabled ? (
            <KeycloakSettings
              isPendingActionsLoading={pendingActionsQuery.isLoading}
            />
          ) : (
            <Settings isPendingActionsLoading={pendingActionsQuery.isLoading} />
          ))}
      </WhmStack>
      <Snackbar />
    </SnackbarProvider>
  );
};
