import TranslationKeys from "../../../../contexts/I18nContext/translationKeys";
import {
  WhmBox,
  WhmIcons,
  WhmTextField,
  WhmTooltip,
} from "@securitize/reactjs-whm";
import { Translate, TranslateHtml } from "../../../Translation/Translate";
import { InfoCard } from "../../shared/InfoCard";
import React from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { InvestorDto } from "../../../../data/dtos/investor.dto";
import {
  columnBox,
  textFieldStyle,
  textFieldTooltipBox,
  wrapBox,
} from "../../shared/styles";

interface BusinessInformationCardProps {
  investorInfoQuery: UseQueryResult<InvestorDto, Error>;
}

export const BusinessInformationCard: React.FC<
  React.PropsWithChildren<BusinessInformationCardProps>
> = ({ investorInfoQuery }) => {
  return (
    <InfoCard
      title={
        <Translate
          translationKey={
            TranslationKeys.PROFILE_ENTITY_INFORMATION_BUSINESS_INFORMATION_CARD_TITLE
          }
        />
      }
      subtitle={
        <Translate
          translationKey={
            TranslationKeys.PROFILE_ENTITY_INFORMATION_BUSINESS_INFORMATION_CARD_SUBTITLE
          }
        />
      }
      footer={
        <TranslateHtml
          translationKey={
            TranslationKeys.PROFILE_ENTITY_INFORMATION_BUSINESS_INFORMATION_CARD_FOOTER
          }
        />
      }
    >
      <WhmBox
        component="form"
        sx={{
          ...columnBox,
          marginTop: "32px",
          marginBottom: "24px",
        }}
        noValidate
        autoComplete="off"
      >
        <WhmBox
          sx={{
            ...wrapBox,
            gap: "16px",
          }}
        >
          <WhmTextField
            id="website"
            inputProps={{
              "data-test-id": "profile-entity-information-website-field",
            }}
            label={
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_ENTITY_INFORMATION_FIELDS_WEBSITE
                }
              />
            }
            defaultValue={investorInfoQuery.data?.details.website}
            sx={textFieldStyle}
            disabled
          />
          <WhmBox sx={textFieldTooltipBox}>
            <WhmTextField
              id="leiCode"
              inputProps={{
                "data-test-id": "profile-entity-information-lei-code-field",
              }}
              label={
                <Translate
                  translationKey={
                    TranslationKeys.PROFILE_ENTITY_INFORMATION_FIELDS_LEI_CODE
                  }
                />
              }
              defaultValue={investorInfoQuery.data?.details.leiCode}
              sx={{ flexGrow: 1, marginRight: "8px" }}
              disabled
            />
            <WhmTooltip
              placement="top-start"
              componentsProps={{
                tooltip: {
                  sx: {
                    ul: {
                      paddingLeft: "16px",
                      listStyleType: "unset",
                    },
                  },
                },
              }}
              data-test-id="profile-entity-information-tax-id-tooltip"
              title={
                <TranslateHtml
                  translationKey={
                    TranslationKeys.PROFILE_ENTITY_INFORMATION_BUSINESS_INFORMATION_CARD_LEI_TOOLTIP
                  }
                />
              }
            >
              <WhmIcons.InfoOutlined
                sx={{ width: "16px", height: "16px", color: "GrayText" }}
              />
            </WhmTooltip>
          </WhmBox>
        </WhmBox>

        <WhmBox
          sx={{
            ...wrapBox,
            gap: "16px",
            marginTop: "16px",
          }}
        >
          <WhmTextField
            id="sourceOfFunds"
            multiline
            minRows={3}
            maxRows={4}
            inputProps={{
              "data-test-id":
                "profile-entity-information-source-of-funds-field",
            }}
            label={
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_ENTITY_INFORMATION_FIELDS_SOURCE_OF_FUNDS
                }
              />
            }
            defaultValue={investorInfoQuery.data?.details.sourceOfFunds}
            sx={textFieldStyle}
            disabled
          />
          <WhmTextField
            id="lineOfBusiness"
            multiline
            minRows={3}
            maxRows={4}
            inputProps={{
              "data-test-id":
                "profile-entity-information-line-of-business-field",
            }}
            label={
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_ENTITY_INFORMATION_FIELDS_LINE_OF_BUSINESS
                }
              />
            }
            defaultValue={investorInfoQuery.data?.details.lineOfBusiness}
            sx={textFieldStyle}
            disabled
          />
        </WhmBox>
      </WhmBox>
    </InfoCard>
  );
};
