import { WhmStack, WhmTypography } from "@securitize/reactjs-whm";
import Empty from "../../../assets/Empty.svg";
import EmptyEntity from "../../../assets/Empty-entity.svg";
import { Translate, TranslateHtml } from "../../Translation/Translate";
import TranslationKeys from "../../../contexts/I18nContext/translationKeys";
import React from "react";
import { useNavigate } from "react-router";
import { VERIFICATION_URL } from "../routing-constants";
import { WhmCustomButton } from "../../shared/WhmCustomButton";
import { InvestorType, InvestorTypes } from "../../../data/types/investors";

interface VerificationNeededProps {
  currentPath: string;
  investorType: InvestorType;
  entityName?: string;
}

export const VerificationNeeded: React.FC<VerificationNeededProps> = ({
  currentPath,
  investorType,
  entityName,
}) => {
  const navigate = useNavigate();
  const isEntity = investorType === InvestorTypes.ENTITY;

  return (
    <WhmStack sx={{ padding: "24px", alignItems: "center" }}>
      <img
        src={isEntity ? EmptyEntity : Empty}
        alt="profile"
        style={{ height: "124px" }}
      />

      <WhmTypography
        variant={"h4"}
        sx={{ marginTop: "16px", textAlign: "center" }}
      >
        <Translate
          translationKey={
            isEntity
              ? TranslationKeys.PROFILE_ENTITY_INFORMATION_EMPTY_TITLE
              : TranslationKeys.PROFILE_PERSONAL_INFORMATION_EMPTY_TITLE
          }
          replacements={isEntity ? { entityName: entityName ?? "" } : {}}
        />
      </WhmTypography>
      <WhmTypography
        variant={"body1"}
        sx={{ textAlign: "center", marginTop: "8px" }}
      >
        <TranslateHtml
          translationKey={
            isEntity
              ? TranslationKeys.PROFILE_ENTITY_INFORMATION_EMPTY_SUBTITLE
              : TranslationKeys.PROFILE_PERSONAL_INFORMATION_EMPTY_SUBTITLE
          }
        />
      </WhmTypography>
      <WhmCustomButton
        onClick={() => navigate(VERIFICATION_URL(currentPath))}
        variant="contained"
        color="primary"
        data-test-id="profile-information-empty-cta"
        sx={{ marginTop: "16px" }}
      >
        <Translate
          translationKey={
            isEntity
              ? TranslationKeys.PROFILE_ENTITY_INFORMATION_EMPTY_CTA
              : TranslationKeys.PROFILE_PERSONAL_INFORMATION_EMPTY_CTA
          }
        />
      </WhmCustomButton>
    </WhmStack>
  );
};
