import {
  WhmAlert,
  WhmIconButton,
  WhmStack,
  whmTheme,
  WhmTypography,
} from "@securitize/reactjs-whm";
import Error from "../../../assets/Error.svg";
import { Translate, TranslateHtml } from "../../Translation/Translate";
import TranslationKeys from "../../../contexts/I18nContext/translationKeys";
import React, { useContext } from "react";
import { WhmCustomButton } from "../../shared/WhmCustomButton";
import { ReactComponent as ErrorIcon } from "@securitize/assets-sec-ui/dist/icons/svg/advise.svg";
import { ReactComponent as CopyIcon } from "@securitize/assets-sec-ui/dist/icons/svg/copy.svg";
import { ReactComponent as RefreshIcon } from "@securitize/assets-sec-ui/dist/icons/svg/refresh.svg";
import { ReactComponent as CheckIcon } from "@securitize/assets-sec-ui/dist/icons/svg/check.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import { mobileShare } from "../../../helpers/mobile-copy";
import { SnackbarContext } from "../../shared/Snackbar";

interface ErrorPageProps {
  errorCode?: string;
  onRefresh?: (() => void) | (() => Promise<any>);
}

export const ErrorPage: React.FC<ErrorPageProps> = ({
  errorCode,
  onRefresh,
}) => {
  const maskedErrorCode = errorCode?.slice(-8);
  const { showSnackbar } = useContext(SnackbarContext);

  const handleCopy = () => {
    if (mobileShare(maskedErrorCode || "")) {
      // If we trigger the share in mobile, we don't need the snackbar message
      return;
    }

    showSnackbar({
      severity: "info",
      message: (
        <WhmStack gap="16px" direction="row" sx={{ alignItems: "center" }}>
          <WhmTypography
            sx={{
              fill: whmTheme.palette.common.white,
              marginTop: { xl: "2px" },
            }}
          >
            <CheckIcon fontSize="inherit" />
          </WhmTypography>
          <Translate
            translationKey={TranslationKeys.PROFILE_ERROR_CODE_COPIED}
          />
        </WhmStack>
      ),
    });
  };

  return (
    <WhmStack sx={{ padding: "24px", alignItems: "center" }}>
      <img src={Error} alt="error" style={{ height: "124px" }} />

      <WhmTypography
        variant={"h4"}
        sx={{ marginTop: "16px", textAlign: "center" }}
      >
        <Translate translationKey={TranslationKeys.PROFILE_ERROR_TITLE} />
      </WhmTypography>
      <WhmTypography
        variant={"body1"}
        sx={{ textAlign: "center", marginTop: "8px", marginBottom: "24px" }}
      >
        <TranslateHtml
          translationKey={
            maskedErrorCode
              ? TranslationKeys.PROFILE_ERROR_SUBTITLE_WITH_CODE
              : TranslationKeys.PROFILE_ERROR_SUBTITLE
          }
        />
      </WhmTypography>

      {maskedErrorCode && (
        <WhmAlert
          severity="error"
          sx={{
            padding: "6px 16px",
            marginBottom: "12px",
            ".MuiAlert-message": {
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              display: "flex",
              gap: "8px",
              alignItems: "center",
              padding: "0",
            },
            ".MuiAlert-icon": {
              alignItems: "center",
            },
          }}
          icon={<ErrorIcon width={22} height={22} fill="currentColor" />}
        >
          <WhmTypography variant="body2">
            <Translate
              translationKey={TranslationKeys.PROFILE_ERROR_ALERT}
              replacements={{ errorCode: maskedErrorCode }}
            />
          </WhmTypography>

          <CopyToClipboard text={maskedErrorCode} onCopy={handleCopy}>
            <WhmIconButton
              aria-label="copy"
              sx={{
                color: whmTheme.palette.secondary.main,
                padding: "4px",
                marginLeft: "16px",
              }}
            >
              <CopyIcon width={20} height={20} fill="currentColor" />
            </WhmIconButton>
          </CopyToClipboard>
        </WhmAlert>
      )}

      <WhmTypography
        variant={"body1"}
        sx={{
          textAlign: "center",
          fontSize: "14px",
          a: {
            textDecoration: "underline !important",
            color: whmTheme.palette.primary.main,
          },
        }}
      >
        <TranslateHtml translationKey={TranslationKeys.PROFILE_ERROR_FOOTER} />
      </WhmTypography>

      <WhmCustomButton
        onClick={onRefresh}
        variant="contained"
        color="primary"
        data-test-id="profile-error-cta"
        sx={{ marginTop: "24px" }}
        endIcon={<RefreshIcon fill={whmTheme.palette.primary.contrastText} />}
      >
        <Translate translationKey={TranslationKeys.PROFILE_ERROR_CTA} />
      </WhmCustomButton>
    </WhmStack>
  );
};
