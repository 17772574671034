import TranslationKeys from "../../../../contexts/I18nContext/translationKeys";
import {
  WhmAutocomplete,
  WhmBox,
  WhmIcons,
  WhmInputAdornment,
  WhmTextField,
  whmTheme,
  WhmTooltip,
} from "@securitize/reactjs-whm";
import { Translate, TranslateHtml } from "../../../Translation/Translate";
import { InfoCard } from "../../shared/InfoCard";
import React, { useContext } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { InvestorDto } from "../../../../data/dtos/investor.dto";
import { entityTypesTranslations } from "./entityTypeList";
import { I18nContext } from "../../../../contexts/I18nContext";
import {
  columnBox,
  placeholderTextFieldStyle,
  textFieldStyle,
  textFieldTooltipBox,
  wrapBox,
} from "../../shared/styles";

interface EntityInfoCardProps {
  investorInfoQuery: UseQueryResult<InvestorDto, Error>;
}

export const EntityInformationCard: React.FC<
  React.PropsWithChildren<EntityInfoCardProps>
> = ({ investorInfoQuery }) => {
  const { getTranslation, language } = useContext(I18nContext);

  const dateConfig: { locale: string; options: Intl.DateTimeFormatOptions } = {
    locale: language === "en" ? "en-US" : "es-ES",
    options: {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    },
  };

  const autoCompleteEntityTypeValue = investorInfoQuery.data?.details.entityType
    ? {
        value: investorInfoQuery.data.details.entityType,
        label: getTranslation(
          entityTypesTranslations[investorInfoQuery.data.details.entityType],
        ),
      }
    : undefined;

  return (
    <InfoCard
      title={
        <Translate
          translationKey={
            TranslationKeys.PROFILE_ENTITY_INFORMATION_ENTITY_INFORMATION_CARD_TITLE
          }
        />
      }
      subtitle={
        <Translate
          translationKey={
            TranslationKeys.PROFILE_ENTITY_INFORMATION_ENTITY_INFORMATION_CARD_SUBTITLE
          }
        />
      }
      footer={
        <TranslateHtml
          translationKey={
            TranslationKeys.PROFILE_ENTITY_INFORMATION_ENTITY_INFORMATION_CARD_FOOTER
          }
        />
      }
    >
      <WhmBox
        component="form"
        sx={{
          ...columnBox,
          marginTop: "32px",
          marginBottom: "24px",
        }}
        noValidate
        autoComplete="off"
      >
        <WhmBox
          sx={{
            ...wrapBox,
            gap: "16px",
          }}
        >
          <WhmTextField
            id="entityName"
            inputProps={{
              "data-test-id": "profile-entity-information-legal-name-field",
            }}
            label={
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_ENTITY_INFORMATION_FIELDS_ENTITY_NAME
                }
              />
            }
            defaultValue={investorInfoQuery.data?.details.entityName}
            sx={textFieldStyle}
            disabled
          />
          <WhmAutocomplete
            options={[autoCompleteEntityTypeValue]}
            value={autoCompleteEntityTypeValue}
            fullWidth
            disabled
            renderInput={(props) => (
              <WhmTextField
                {...props}
                inputProps={{
                  // eslint-disable-next-line react/prop-types
                  ...props.inputProps,
                  "data-test-id":
                    "profile-entity-information-entity-type-field",
                }}
                label={
                  <Translate
                    translationKey={
                      TranslationKeys.PROFILE_ENTITY_INFORMATION_FIELDS_ENTITY_TYPE
                    }
                  />
                }
                name="entityType"
              />
            )}
          />
          <WhmTextField
            id="incorporationDate"
            label={
              <Translate
                translationKey={
                  TranslationKeys.PROFILE_ENTITY_INFORMATION_FIELDS_INCORPORATION_DATE
                }
              />
            }
            type="text"
            disabled
            defaultValue={
              investorInfoQuery.data?.details.incorporationDate !== undefined
                ? new Date(
                    investorInfoQuery.data.details.incorporationDate,
                  ).toLocaleDateString(dateConfig.locale, dateConfig.options)
                : ""
            }
            InputLabelProps={{
              shrink: true,
            }}
            sx={textFieldStyle}
            inputProps={{
              "data-test-id":
                "profile-entity-information-incorporation-date-field",
            }}
            InputProps={{
              endAdornment: (
                <WhmInputAdornment position="end">
                  <WhmIcons.CalendarTodayOutlined
                    sx={{
                      width: "24px",
                      height: "24px",
                      color: whmTheme.palette.action.disabled,
                    }}
                  />
                </WhmInputAdornment>
              ),
            }}
          />
        </WhmBox>

        <WhmBox
          sx={{
            ...wrapBox,
            gap: "16px",
            marginTop: "16px",
          }}
        >
          <WhmBox sx={textFieldTooltipBox}>
            <WhmTextField
              id="taxId"
              inputProps={{
                "data-test-id": "profile-entity-information-tax-id-field",
              }}
              label={
                <Translate
                  translationKey={
                    TranslationKeys.PROFILE_ENTITY_INFORMATION_FIELDS_TAX_ID
                  }
                />
              }
              defaultValue={investorInfoQuery.data?.details.entityIdNumber}
              sx={{ flexGrow: 1, marginRight: "8px" }}
              disabled
            />
            <WhmTooltip
              placement="top-start"
              componentsProps={{
                tooltip: {
                  sx: {
                    ul: {
                      paddingLeft: "16px",
                      listStyleType: "unset",
                    },
                  },
                },
              }}
              data-test-id="profile-entity-information-tax-id-tooltip"
              title={
                <TranslateHtml
                  translationKey={
                    TranslationKeys.PROFILE_ENTITY_INFORMATION_ENTITY_INFORMATION_CARD_TAX_ID_TOOLTIP
                  }
                />
              }
            >
              <WhmIcons.InfoOutlined
                sx={{ width: "16px", height: "16px", color: "GrayText" }}
              />
            </WhmTooltip>
          </WhmBox>

          <WhmBox sx={textFieldTooltipBox}>
            <WhmTextField
              id="dba"
              inputProps={{
                "data-test-id": "profile-entity-information-dba-field",
              }}
              label={
                <Translate
                  translationKey={
                    TranslationKeys.PROFILE_ENTITY_INFORMATION_FIELDS_DBA
                  }
                />
              }
              defaultValue={investorInfoQuery.data?.details.businesses}
              sx={{ flexGrow: 1, marginRight: "8px" }}
              disabled
            />
            <WhmTooltip
              placement="top-start"
              componentsProps={{
                tooltip: {
                  sx: {
                    ul: {
                      paddingLeft: "16px",
                      listStyleType: "unset",
                    },
                  },
                },
              }}
              data-test-id="profile-entity-information-dba-tooltip"
              title={
                <TranslateHtml
                  translationKey={
                    TranslationKeys.PROFILE_ENTITY_INFORMATION_ENTITY_INFORMATION_CARD_DBA_TOOLTIP
                  }
                />
              }
            >
              <WhmIcons.InfoOutlined
                sx={{ width: "16px", height: "16px", color: "GrayText" }}
              />
            </WhmTooltip>
          </WhmBox>

          <WhmBox sx={placeholderTextFieldStyle} />
        </WhmBox>
      </WhmBox>
    </InfoCard>
  );
};
